import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import styles from "../../styles/uninstall-page.module.scss";
import Logo from "../../images/svgs/logo.svg";
import ContentBg from "../../components/backgrounds/uninstall-content-bg";
import CustomizeTabImg from "../../components/customize-tab-img";
import GoogleLogo from "../../components/imageComponents/google";
import YahooLogo from "../../components/imageComponents/yahoo";
import DuckDuckGoLogo from "../../components/imageComponents/duckDuckGo";
import AskLogo from "../../components/imageComponents/ask";
import BingLogo from "../../components/imageComponents/bing";
import BackgroundImage from "gatsby-background-image";
import BlueArrow from "../../images/svgs/arrow.svg";
import { WEATHERBUG_ALS_REINSTALL_LINK } from "../../constants";

const UninstallPage = () => {
  const { mainBackground } = useStaticQuery(graphql`
    query {
      mainBackground: file(relativePath: { eq: "install-bg.jpg" }) {
        childImageSharp {
          fluid(quality: 100, webpQuality: 100, maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <BackgroundImage fluid={mainBackground.childImageSharp.fluid} className={styles.mainBg}>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.logo}>
            <Logo />
          </div>
          <a href={WEATHERBUG_ALS_REINSTALL_LINK} className={styles.reinstalBtn}>
            Re-Install
          </a>
        </div>

        <div className={styles.content}>
          <ContentBg className={styles.contentBg} />
          <div className={styles.tabImage}>
            <CustomizeTabImg />
          </div>
          <div className={styles.messageContainer}>
            <div className={styles.messageTitle}>
              Didn't like the <br /> search experience?
            </div>
            <div className={styles.messageText}>
              You can change your search settings <br /> and still enjoy Instant News!
            </div>
            <div className={styles.messageLogos}>
              <span>
                <GoogleLogo />
              </span>
              <span>
                <DuckDuckGoLogo />
              </span>
              <span>
                <BingLogo />
              </span>
              <span>
                <YahooLogo />
              </span>
              <span>
                <AskLogo />
              </span>
              <div className={styles.blueArrow}>
                <BlueArrow />
              </div>
            </div>
            <a href={WEATHERBUG_ALS_REINSTALL_LINK} className={styles.reinstalBtn}>
              Re-Install
            </a>
          </div>
        </div>
      </div>
    </BackgroundImage>
  );
};

export default UninstallPage;
